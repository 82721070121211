import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { FileField, TextField } from 'components/fields';
import { Form } from 'components/form';
import { Img } from 'components/image';
import { CopyInput } from 'components/input';
import { Article, Paragraph, Title } from 'components/typography';
import { ConnectionStepProps } from 'interfaces/step-props';
import React from 'react';
import { useFeature } from 'utils/feature-flags';

export const Auth0CreateApplication: React.FC<
  Readonly<ConnectionStepProps>
> = ({ appName, connection, onNextStep }) => (
  <Article>
    <Title>Step 1: Create SAML Application</Title>

    <Paragraph>
      In your Auth0 Dashboard, select Applications {'>'} Applications from the
      sidebar. Then select “Create Application”.
    </Paragraph>

    <Img priority height={556} src="/sso/auth0/v1/auth0-1.png" width={1475} />

    <Paragraph>
      Enter an “Application Name” that describes {appName}, select the “Regular
      Web Applications” type, then click “Create”.
    </Paragraph>

    <Img priority height={1240} src="/sso/auth0/v1/auth0-2.png" width={1596} />

    <Paragraph>
      Select the “Addons” tab in your new application, and then open the SAML2
      WEB APP menu.
    </Paragraph>

    <Img height={412} src="/sso/auth0/v1/auth0-3.png" width={1030} />

    <CopyInput
      label="Copy this ACS URL / Single Sign-On URL"
      value={connection?.saml_acs_url}
    />

    <Paragraph>
      Enter the ACS URL provided above in the “Application Callback URL” input
      box.
    </Paragraph>

    <Img height={1328} src="/sso/auth0/v1/auth0-4.png" width={1274} />

    <Paragraph>
      Scroll down to the bottom of the AddOn modal and select “Enable”.
    </Paragraph>

    <Img height={1784} src="/sso/auth0/v1/auth0-5.png" width={1276} />

    <Confirm
      label="I’ve configured the ACS URL in the SAML application."
      onClick={onNextStep}
    />
  </Article>
);

export const Auth0UploadMetadata: React.FC<Readonly<ConnectionStepProps>> = ({
  connection,
  errors,
  isLoading,
  onFileInput,
  onInputChange,
  onNextStep,
  validationErrors,
}) => {
  const isUseGeneratedConnectionEntityIdEnabled = useFeature(
    'useGeneratedConnectionEntityId',
  );

  return (
    <Article>
      <Title>Step 2: Upload Identity Provider Metadata</Title>

      <Paragraph>
        On the Usage tab of the Addon menu, you are given an overview of your
        Identity Provider metadata.
      </Paragraph>

      <Img
        priority
        height={1320}
        src="/sso/auth0/v1/auth0-6.png"
        width={1268}
      />

      {!isUseGeneratedConnectionEntityIdEnabled && (
        <Paragraph>
          Copy the “Issuer” to use as the Identity Provider Issuer below.
        </Paragraph>
      )}

      <Paragraph>
        Copy the “Identity Provider Login URL” to use as the Identity Provider
        Single Sign-On URL below.
      </Paragraph>

      <Paragraph>
        Download the “Identity Provider Certificate” to use as the X.509
        Certificate below.
      </Paragraph>

      <Card>
        <Form
          disabled={
            !connection?.saml_idp_url ||
            !connection?.saml_x509_certs ||
            !connection?.saml_entity_id ||
            validationErrors?.saml_idp_url?.value === connection?.saml_idp_url
          }
          isLoading={isLoading}
          isUpdate={
            !!(
              errors?.saml_idp_url ||
              errors?.saml_entity_id ||
              errors?.saml_x509_certs
            )
          }
          onSubmit={onNextStep}
        >
          <TextField
            autoFocus={true}
            error={errors?.saml_idp_url || validationErrors?.saml_idp_url}
            label="1. Identity Provider Single Sign-On URL"
            name="saml_idp_url"
            onChange={onInputChange}
            placeholder="https://foo-corp.okta.com/app/exkgq2/sso/saml"
            value={connection?.saml_idp_url}
          />
          {isUseGeneratedConnectionEntityIdEnabled ? (
            <FileField
              error={errors?.saml_x509_certs}
              filename="X.509 Certificate"
              label="2. X.509 Certificate"
              name="saml_x509_certs"
              onUpload={onFileInput}
              value={connection?.saml_x509_certs?.[0]}
            />
          ) : (
            <>
              <TextField
                error={errors?.saml_entity_id}
                label="2. Identity Provider Issuer"
                name="saml_entity_id"
                onChange={onInputChange}
                placeholder="http://www.okta.com/exkgq2c19CUpt2Brr46"
                value={connection?.saml_entity_id}
              />

              <FileField
                error={errors?.saml_x509_certs}
                filename="X.509 Certificate"
                label="3. X.509 Certificate"
                name="saml_x509_certs"
                onUpload={onFileInput}
                value={connection?.saml_x509_certs?.[0]}
              />
            </>
          )}
        </Form>
      </Card>
    </Article>
  );
};
